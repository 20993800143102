import React, { useState } from 'react';
import { Form, Input, Button, Radio, message, AutoComplete, Row, Col } from 'antd';
import AuthService from "../../../Services/auth";
import BoxService from "../../../Services/box";

const SaleForm = ({ initialValues, onSubmit }) => {

    const [loading, setLoading] = useState(false);
    const defaultValues = {
        box_id: 0,
        type: '',
        before_t: 0,
        t_weight: 0,
        after_t: 0,
        reference: ''
    }
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(initialValues || defaultValues);
    const [option, setOption] = useState([]);


    const formItemLayout = {
        // labelCol: {
        //     span: 8,
        // },
        // wrapperCol: {
        //     span: 14,
        // },
        labelCol: {
            xs: { span: 8 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 14 },
            sm: { span: 14 },
            md: { span: 10 },
            lg: { span: 10 }
        },
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    // const formLayout = {
    //     labelCol: {
    //         span: 8,
    //     },
    //     wrapperCol: {
    //         span: 14,
    //     },
    // }

    const handleInputChange = (fieldName, value) => {
        if (value) {
            setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    const calculateTransaction = (fieldName, value) => {
        if (value) {
            // cal
            const before_t = formData?.before_t;
            const t = value;
            const after = parseFloat(before_t) - parseFloat(t)
            if (after >= 0) {
                setFormData((prevData) => ({ ...prevData, after_t: after.toFixed(4), t_weight: value }));

            } else if (after < 0) {
                setFormData((prevData) => ({ ...prevData, after_t: '', t_weight: 0 }));
            }

        }
    }

    const calculateTransactionPcs = (fieldName, value) => {
        if (value) {
            // cal
            const before_t_pcs = formData?.before_t_pcs;
            const t = value;
            const after = parseInt(before_t_pcs) - parseInt(t)
            if (after >= 0) {
                setFormData((prevData) => ({ ...prevData, after_t_pcs: after, t_pcs: value }));

            } else if (after < 0) {
                setFormData((prevData) => ({ ...prevData, after_t_pcs: '', t_pcs: 0 }));
            }

        }
    }

    const onFinish = async () => {
        // Handle form submission, e.g., send data to the server
        console.log('Received values:', formData);
        try {
            console.log(formData)
            // if (formData.t_weight <= 0 || formData.t_weight === '') {
            //     message.error('Transaction weight is invalid.')
            //     return
            // }
            // if (formData.after_t <= 0 || formData.after_t === '') {
            //     message.error('Transaction is invalid.')
            //     return
            // }
            if (!(parseFloat(formData.t_weight) > 0 || parseInt(formData.t_pcs) > 0)) {
                message.error('Transaction weight or pcs should be greater than 0.');
                return;
            }
            setLoading(true)
            const after_t = formData.after_t;
            let after_t_pcs = formData.after_t_pcs;
            after_t_pcs = parseInt(after_t_pcs)
            console.log(after_t_pcs)
            // const response = await axios.post(`${apiUrl}record`, formData);
            BoxService.pushTransaction(formData).then(
                (response) => {
                    if (response.data.status === 'success') {
                        form.setFieldsValue({
                            type: "",
                            reference: "",
                            before_t: after_t,
                            t_weight: 0,
                            after_t: 0,
                            before_t_pcs: after_t_pcs,
                            t_weight_pcs: 0,
                            after_t_pcs: 0,
                        });
                        setFormData((prevData) => ({ ...prevData, before_t: after_t, t_weight: '', after_t: '', before_t_pcs: after_t_pcs, t_pcs: '', after_t_pcs: '', reference: '' }));
                        console.log(formData)
                        //message.success(response.data.message)
                        onSubmit(response.data);
                        setLoading(false)
                    } else {
                        //message.error('Form submission failed')
                        onSubmit({ status: 'failed' });
                        setLoading(false)
                    }
                },
                (error) => {
                    console.log("Private page", error.response);
                    // Invalid token
                    if (error.response && error.response.status === 401 || error.response.status === 403) {
                        AuthService.logout();
                        window.location.href = '/login';
                        window.location.reload();
                    }
                }
            );

        } catch (error) {
            message.error('Form submission failed')
            console.error('Error submitting form:', error);
            onSubmit({ status: 'failed' });
        }
    };

    const options = Array.from({ length: 50 }, (_, index) => ({ value: `D${index + 1}` }));


    return (

        <Form
            form={form}
            name="box-form"
            onFinish={onFinish}
            {...formItemLayout}
            // layout={formLayout}
            initialValues={initialValues}
            style={{ width: '100%' }}
        >
            <Input
                type='hidden'
                name='box_id'
                value={formData.box_id}
            />
            <Row>
                <Col span={24}>
                    <Form.Item
                        //label="Select Sale Type"
                        label={null}  // Set label to null to hide it
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select type'
                            },
                        ]}
                        //wrapperCol={{ ...formItemLayout.wrapperCol, span: 20, offset: 4 }}  // Adjust span and offset as needed
                        wrapperCol={{
                            xs: { span: 24, offset: 0 }, // for mobile devices
                            sm: { span: 22, offset: 0 }, // for small devices (tablets)
                            md: { span: 18, offset: 6 }, // for medium devices (desktops)
                            lg: { span: 18, offset: 6 }, // for large devices (large desktops)
                        }}
                        style={{ textAlign: 'center' }}  // Center the content
                    >
                        <Radio.Group name='type' size={"medium"} value={formData.type} onChange={(e) => handleInputChange('type', e.target.value)} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                            <Radio.Button value="SALE" style={{ fontSize: 12 }}>Sale</Radio.Button>
                            <Radio.Button value="BYHAND" style={{ fontSize: 12 }}>ByHand</Radio.Button>
                            <Radio.Button value="DISPLAY" style={{ fontSize: 12 }}>Display</Radio.Button>
                            <Radio.Button value="EXCHANGE" style={{ fontSize: 12 }}>Exchange</Radio.Button>
                            <Radio.Button value="REPAIR" style={{ fontSize: 12 }}>Repair</Radio.Button>
                        </Radio.Group>

                    </Form.Item>
                </Col>
            </Row >

            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Box No."
                        name="number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Box Number'
                            },
                            { type: 'text' },
                        ]}
                    >
                        <Input
                            readOnly
                            name='number'
                            placeholder="Box number"
                            value={formData.number}

                        />

                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Box Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Box Name'
                            },
                            { type: 'text' },
                        ]}
                    >
                        <Input
                            readOnly
                            name='name'
                            placeholder="Box name"
                            value={formData.name}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Wt. Before T"
                        name="before_t"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value'
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            name='before_t'
                            value={formData.before_t}
                            onChange={(e) => handleInputChange('before_t', e?.target?.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="T Weight"
                        name="t_weight"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Transaction Weight'
                            },
                            { type: 'decimal' },
                        ]}
                    >
                        <Input
                            name='t_weight'
                            placeholder="Transaction Weight"
                            value={formData.t_weight}
                            onChange={(e) => calculateTransaction('t_weight', e?.target?.value)}
                            type='number'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Wt. After T "
                        name="after_t"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value',
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            value={formData.after_t}
                            onChange={(e) => handleInputChange('after_t', e?.target?.value)}
                        />
                        <span style={{ display: 'none' }}>{formData.after_t}</span>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Pcs Before T"
                        name="before_t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value'
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            name='before_t_pcs'
                            value={formData.before_t_pcs}
                            onChange={(e) => handleInputChange('before_t_pcs', e?.target?.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="T Pcs"
                        name="t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Transaction Pcs'
                            },
                            { type: 'decimal' },
                        ]}
                    >

                        <Input
                            name='t_pcs'
                            placeholder="Transaction Pcs"
                            value={formData.t_pcs}
                            onChange={(e) => calculateTransactionPcs('t_pcs', e?.target?.value)}
                            type='number'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Pcs After T "
                        name="after_t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value',
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            value={formData.after_t_pcs}
                            onChange={(e) => handleInputChange('after_t_pcs', e?.target?.value)}
                        />
                        <span style={{ display: 'none' }}>{formData.after_t_pcs}</span>
                    </Form.Item>
                </Col>
            </Row>


            <Form.Item
                label="Reference"
                name="reference"
                rules={[
                    {
                        required: true,
                        message: 'Please enter reference for transaction'
                    },
                    { type: 'text' },
                ]}
            >

                {
                    formData.type === 'DISPLAY' ?
                        <AutoComplete
                            style={{
                                width: 200,
                            }}
                            name='reference'
                            options={options}
                            placeholder="type 1-50"
                            onSearch={(text) => setOption((text))}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(e) => handleInputChange('reference', e)}
                        /> :
                        <Input
                            name='reference'
                            placeholder="Reference for transaction"
                            value={formData.reference}
                            onChange={(e) => handleInputChange('reference', e?.target?.value)}
                        />
                }


            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>



    );
};

export default SaleForm;
