import React, { useEffect, useState } from 'react';
import { Divider, Col, Row, Table, Card, Spin, DatePicker, Tag } from 'antd';
import AuthService from "../Services/auth";
import BoxService from "../Services/box";


const Report = () => {
    const [loading, setLoading] = useState(false);
    const [boxes, setBoxes] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [theme, setTheme] = useState('light')

    useEffect(() => {
        const color = localStorage.getItem('app_theme')
        setTheme(color)
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };


    const columns = [
        {
            title: 'Date time',
            dataIndex: 'created_at',
            width: '10%',
            render: (text, record) => {
                return formatDate(text)
            }
            // align: 'right',
        },
        {
            title: 'Box Number',
            dataIndex: 'number',
            width: '20%',
        },
        {
            title: 'T Type',
            dataIndex: 'type',
            width: '10%',
            render: (text, record) => (
                <span>
                    <Tag color={(text === 'ADDSTOCK') ? 'green' : 'red'}>
                        {text}
                    </Tag>
                </span >
            ),
        },
        {
            title: 'Before T Wt.',
            dataIndex: 'before_tran_weight',
        },
        {
            title: 'Tran Weight',
            dataIndex: 'tran_weight',
        },
        {
            title: 'After T Wt.',
            dataIndex: 'after_tran_weight',
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
        }
    ];

    const onChange = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        const fetchData = async () => {
            try {
                //const response = await axios.get(apiUrl + 'boxes');
                setLoading(true)
                BoxService.getTransaction(dateString).then(
                    (response) => {
                        if (response.data) {
                            setBoxes(response.data);
                        }
                        setLoading(false);
                    },
                    (error) => {
                        console.log("Private page", error.response);
                        // Invalid token
                        if (error.response && error.response.status === 401 || error.response.status === 403) {
                            AuthService.logout();
                            window.location.href = '/login';
                            window.location.reload();
                        }
                    }
                );

            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    };


    return (
        <>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card
                        title={<Divider orientation="left">Report</Divider>}
                    >

                        <Row>
                            <Col xs={24} sm={24} md={16} lg={16} className='mt-1'>
                                <DatePicker onChange={onChange} autoFocus readOnly />
                            </Col>
                        </Row>
                        <Divider orientation="left"></Divider>
                        <Table
                            loading={loading}
                            size='small'
                            rowKey="id"
                            columns={columns}
                            dataSource={boxes}
                            bordered
                            pagination={false}
                        //  title={() =>''}
                        //footer={() => 'Footer'}
                        />
                    </Card>
                </Col>
            </Row>
        </>

    )
}
export default Report;