import axios from "axios";

const apiUrl =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_URL_LOCAL
        : process.env.REACT_APP_API_URL_PROD;

const signup = (email, password) => {
    return axios
        .post(apiUrl + "/signup", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const login = (values) => {
    return axios
        .post(apiUrl + "login", values)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const changePassword = (values) => {
    const user = getCurrentUser();
    const formData = { ...values, email: user.email }
    return axios
        .post(apiUrl + "change-password", formData)
        .then((response) => {
            if (response.data.token) {
                //localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const isLoggedIn = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
        // return { Authorization: 'Bearer ' + user.accessToken };
        return true;
    } else {
        return false;
    }
};

const authService = {
    signup,
    login,
    logout,
    getCurrentUser,
    isLoggedIn,
    changePassword
};

export default authService;