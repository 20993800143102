import React, { useState } from 'react';
import { Form, Input, Button, message, Row, Col, Card, Divider } from 'antd';
import axios from 'axios';
import AuthService from '../Services/auth'

const ChangePasswordForm = () => {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            // const response = await axios.post('/api/change-password', values);
            await AuthService.changePassword(values).then(
                () => {
                    // console.log(response)
                    // history.push('/home');
                    // window.location.reload();
                    message.success('Password changed successfully. Redirecting ... ');
                    AuthService.logout()
                    const timeoutId = setTimeout(() => {
                        // After 3 seconds, set redirect to true
                        window.location.href = '/';
                    }, 3000);
                },
                (error) => {
                    message.error('Invalid credentials')
                    console.log(error);
                }
            );

        } catch (error) {
            // Handle error
            console.error('Password change failed:', error);
            message.error('Password change failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row>
            <Col xs={24} sm={24} md={5} lg={6}>
                <Card
                    title={<Divider orientation="left">Change Password</Divider>}
                >
                    <Form name="changePassword" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            label="Old Password"
                            name="oldPassword"
                            rules={[{ required: true, message: 'Please enter your old password' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[
                                { required: true, message: 'Please enter your new password' },
                                { min: 6, message: 'Password must be at least 6 characters long' },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm New Password"
                            name="confirmNewPassword"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true, message: 'Please confirm your new password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords do not match');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>


                </Card>
            </Col>
        </Row>


    );
};

export default ChangePasswordForm;

