import React from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { AiOutlineLogout } from "react-icons/ai";
import { Menu, message } from 'antd';
import AuthService from "../../Services/auth";


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const logout = () => {
    AuthService.logout()
    message.success('Logged out successful. Redirecting ... ');
    const timeoutId = setTimeout(() => {
        // After 3 seconds, set redirect to true
        window.location.href = '/';
    }, 3000);

}
const items = [
    getItem(<Link to="/home">Dashboard</Link>, 'link1', <span style={{ fontSize: '18px' }}><MdDashboard /></span>),
    getItem(<Link to="/checklist">Check List</Link>, 'link2', <span style={{ fontSize: '18px' }}><IoCheckmarkDoneSharp /></span >),
    getItem(<Link to="/report">Report</Link>, 'link3', <span style={{ fontSize: '18px' }}><TbReportSearch /></span >),
    getItem(<Link to="/change-password">Change Password</Link>, 'link4', <span style={{ fontSize: '18px' }}><LiaExchangeAltSolid /></span >),
    getItem(<a href="#" onClick={logout}> Logout </a>, 'link5', <span style={{ fontSize: '18px' }}><AiOutlineLogout /></span >),
];
const onClick = (e) => {
    console.log('click', e);
};


const App = () => (
    <Menu
        onClick={onClick}
        mode="inline"
        items={items}
    />
);
export default App;