import React, { useEffect, useState } from 'react';
import { Form, Input, Button, InputNumber } from 'antd';
import AuthService from "../../../Services/auth";
import BoxService from "../../../Services/box";

const BoxForm = ({ initialValues, onSubmit }) => {

    const [loading, setLoading] = useState(false);
    const defaultValues = {
        category: 0,
        id: 0,
        name: '',
        number: '',
        alert: 0,
        weight: 0,
        pcs: 0,
    }
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(initialValues || defaultValues);

    const formItemLayout = {
        // labelCol: {
        //     span: 8,
        // },
        // wrapperCol: {
        //     span: 14,
        // },
        labelCol: {
            xs: { span: 8 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 14 },
            sm: { span: 14 },
            md: { span: 10 },
            lg: { span: 10 }
        },
    }

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const handleInputTxtChange = (fieldName, value) => {
        if (value) {
            const uppercaseValue = value.toUpperCase();
            setFormData((prevData) => ({ ...prevData, [fieldName]: uppercaseValue }));
        }
    };

    const handleInputChange = (fieldName, value) => {
        if (value) {
            setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    const onFinish = async (values) => {
        // Handle form submission, e.g., send data to the server
        console.log('Received values:', formData);
        setLoading(true)
        try {
            //const response = await axios.post(`${apiUrl}boxes`, formData);
            BoxService.addNewBox(formData).then(
                (response) => {
                    onSubmit(response.data);
                    form.resetFields();
                    setLoading(false)
                },
                (error) => {
                    console.log("Private page", error.response);
                    // Invalid token
                    if (error.response && error.response.status === 401 || error.response.status === 403) {
                        AuthService.logout();
                        window.location.href = '/login';
                        window.location.reload();
                    }
                }
            );

        } catch (error) {
            console.error('Error submitting form:', error);
            onSubmit({ status: 'failed' });
            setLoading(false)
        }
    };

    return (
        <Form
            form={form}
            name="box-form"
            onFinish={onFinish}
            {...formItemLayout}
            initialValues={initialValues}
        >
            <Input
                type='hidden'
                value={formData.id}
            />
            <Form.Item
                label="Box No."
                name="number"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your Box Number'
                    },
                    { type: 'text' },
                ]}
            >
                <Input
                    // disabled={formData?.id === 0 ? false : true}
                    placeholder="Box number"
                    value={formData.number}
                    onChange={(e) => handleInputTxtChange('number', e?.target?.value)}
                />

            </Form.Item>

            <Form.Item
                label="Box Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Box Name'
                    },
                    { type: 'text' },
                ]}
            >
                <Input
                    placeholder="Box name"
                    value={formData.name}
                    onChange={(e) => handleInputTxtChange('name', e?.target?.value)}
                />
            </Form.Item>

            <Form.Item
                label="Alert"
                name="alert"
                rules={[
                    {
                        required: true,
                        message: 'Please enter Box alert quantity'
                    },
                    { type: 'decimal' },
                ]}
            >
                <Input
                    style={{
                        width: '100%',
                    }}
                    placeholder="Alert quanity"
                    value={formData.alert}
                    onChange={(e) => handleInputChange('alert', e?.target?.value)}
                />
            </Form.Item>

            {
                initialValues.id === 0 ?
                    <>
                        <Form.Item
                            label="Starting Weight"
                            name="weight"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter Box starting weight'
                                },
                                { type: 'decimal' },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Starting Weight"
                                value={formData.weight}
                                onChange={(e) => handleInputChange('weight', e?.target?.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Starting Pcs"
                            name="pcs"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter Box starting pcs'
                                },
                                {
                                    pattern: /^[0-9]*$/, // Regular expression to allow only numbers
                                    message: 'Please enter a valid number',
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Starting Pcs"
                                value={formData.weight}
                                onChange={(e) => handleInputChange('pcs', e?.target?.value)}
                            />
                        </Form.Item>
                    </>
                    : null
            }


            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BoxForm;
