import React, { useEffect, useState } from 'react';
import { Table, Tag, Timeline } from 'antd';
import AuthService from "../../../Services/auth";
import BoxService from "../../../Services/box";

const ReportTable = ({ boxId }) => {

    const [loading, setLoading] = useState(true);
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        tableData();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };


    const tableData = () => {
        const fetchData = async () => {
            try {
                // const response = await axios.get(`${apiUrl}records/${boxId}`);
                let items = []
                BoxService.getReportByBoxId(boxId).then(
                    (response) => {
                        if (response.data) {
                            const d = response.data;
                            // for (let i = 0; i < d.length; i++) {
                            //     items.push({
                            //         label: formatDate(d[i].created_at),
                            //         children: d[i].type + ' ' + d[i].reference,
                            //         color: d[i].type === 'ADDSTOCK' ? 'green' : 'red',
                            //         position: d[i].type === 'ADDSTOCK' ? 'right' : 'left',
                            //     })
                            // }
                            // setBoxes(items);
                            setBoxes(response.data)
                            setLoading(false)
                        }
                    },
                    (error) => {
                        console.log("Private page", error.response);
                        // Invalid token
                        if (error.response && error.response.status === 401 || error.response.status === 403) {
                            AuthService.logout();
                            window.location.href = '/login';
                            window.location.reload();
                        }
                    }
                );

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }
    const columns = [
        // {
        //     title: 'Box Number',
        //     dataIndex: 'number',
        // },
        {
            title: 'T Type',
            dataIndex: 'type',

            // align: 'right',
            render: (text, record) => (
                <span>
                    <Tag color={(text === 'ADDSTOCK') ? 'green' : 'red'}>
                        {text}
                    </Tag>
                </span >
            ),
        },
        {
            title: 'Before T',
            dataIndex: 'before_tran_weight',
            render: (text, record) => (
                // Format the date using the formatDate function
                <>
                    <span>Wt. :{record.before_tran_weight}</span>
                    <br />
                    <span>Pcs. :{record.before_tran_pcs}</span>
                </>
            ),
        },
        {
            title: 'Transaction',
            dataIndex: 'tran_weight',
            render: (text, record) => (
                // Format the date using the formatDate function
                <>
                    <span>Wt. :{record.tran_weight}</span>
                    <br />
                    <span>Pcs. :{record.tran_pcs}</span>
                </>
            ),
        },
        {
            title: 'After T',
            dataIndex: 'after_tran_weight',
            render: (text, record) => (
                // Format the date using the formatDate function
                <>
                    <span>Wt. :{record.after_tran_weight}</span>
                    <br />
                    <span>Pcs. :{record.after_tran_pcs}</span>
                </>
            ),
        },
        {
            title: 'T Date',
            dataIndex: 'created_at',
            render: (text, record) => (
                // Format the date using the formatDate function
                <span>{formatDate(text)}</span>
            ),
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
        },
    ];
    return (
        <Table
            loading={loading}
            sticky
            size='small'
            rowKey="id"
            columns={columns}
            dataSource={boxes}
            bordered
        //title={() => 'Header'}
        //footer={() => 'Footer'}
        />
        // <Timeline mode='alternate'
        //     items={boxes}
        // />
    );
};

export default ReportTable;
