import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, message, Card, Image } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import AuthService from '../Services/auth';
import { useHistory } from 'react-router-dom';
import backgroundImage from '../background.jpg'
import './index.less'

const Login = () => {

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onFinish = async (values) => {
        try {
            setLoading(true);
            //const response = await axios.post(apiUrl + 'login', values);
            await AuthService.login(values).then(
                () => {
                    // console.log(response)
                    // history.push('/home');
                    // window.location.reload();
                    message.success('Login successful. Redirecting ... ');
                    const timeoutId = setTimeout(() => {
                        // After 3 seconds, set redirect to true
                        window.location.href = '/';
                    }, 3000);
                },
                (error) => {
                    message.error('Invalid credentials')
                    console.log(error);
                }
            );
            // Store the token in local storage or state for further use
            // console.log('Login successful. Token:', response.data.token);
            // Optionally, redirect to another page upon successful login
            // history.push('/home');
        } catch (error) {
            console.error('Login failed:', error.message);
            message.error('Login failed. Please check your credentials.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className='login-page'
        // style={{
        //     backgroundImage: `url(${backgroundImage})`,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundPosition: 'center',
        //     backgroundSize: 'cover',
        // }}
        >
            <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
                <Col style={{ maxWidth: 350, width: 400 }}>
                    <Card className='login-form' align="middle">
                        <h1 style={{ textAlign: 'center' }}>SB v2 </h1>

                        <Image
                            width={124}
                            src="/logo512.png"
                            style={{ borderRadius: '50%', textAlign: 'center' }}
                        />
                        <p style={{ textAlign: 'center' }}>Login</p>
                        <Form
                            name="normal_login"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            style={{ maxWidth: 300, margin: '0 auto' }}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="email"
                                    style={{ height: 40 }} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                    style={{ height: 40 }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" block loading={loading} style={{ height: 40 }}>
                                    Log in
                                </Button>

                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
