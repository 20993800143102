import React, { useEffect, useState } from 'react';
import { Divider, Col, Row, Result, Card, Spin, List, Empty } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import AuthService from "../Services/auth";
import BoxService from "../Services/box";


const App = () => {
    const [loading, setLoading] = useState(true);
    const [boxes, setBoxes] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [theme, setTheme] = useState('light')

    const data = []; // Your data array

    useEffect(() => {
        const color = localStorage.getItem('app_theme')
        setTheme(color)
        const fetchData = async () => {
            try {
                //const response = await axios.get(apiUrl + 'boxes');
                BoxService.getAllBoxes().then(
                    (response) => {
                        if (response.data) {
                            setBoxes(response.data);
                            setLoading(false);
                        }
                    },
                    (error) => {
                        console.log("Private page", error.response);
                        // Invalid token
                        if (error.response && error.response.status === 401 || error.response.status === 403) {
                            AuthService.logout();
                            window.location.href = '/login';
                            window.location.reload();
                        }
                    }
                );

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);

    const handleCardClick = (cardId) => {
        // Check if the card is already selected
        const isCardSelected = selectedCards.includes(cardId);
        // Update the selected cards state
        setSelectedCards((prevSelectedCards) => {
            if (isCardSelected) {
                // Deselect the card
                return prevSelectedCards.filter((id) => id !== cardId);
            } else {
                // Select the card
                return [...prevSelectedCards, cardId];
            }
        });
    };

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    {loading ?
                        <div className="spinContainer">
                            <Spin spinning={loading} size="large" />
                        </div>
                        :
                        <Card
                            title={<Divider orientation="left"> Box - Checklist</Divider>}
                        > <Row>
                                {
                                    boxes.length ? (
                                        boxes.map((item) => (
                                            <Col xs={12} sm={8} md={6} lg={4} key={item.id} className='border'>
                                                <Card bordered={true}
                                                    className={`check-list-box ${selectedCards.includes(item.id) ? `checkBox-green-${theme}` : ''}`}
                                                    onClick={() => handleCardClick(item.id)}>
                                                    {item.number}<br />{item.weight}
                                                </Card>
                                            </Col>
                                        ))
                                    ) :
                                        <Col span={24} className='border'>
                                            <List
                                                dataSource={data}
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        {/* Render your item content here */}
                                                        {item.content}
                                                    </List.Item>
                                                )}
                                                locale={{
                                                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />,
                                                }}
                                            />
                                        </Col>

                                }
                            </Row>
                        </Card>
                    }
                </Col>
            </Row >
        </>
    );
}

export default App;