// App.js or index.js

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { SiJirasoftware } from "react-icons/si";
import { Layout, Menu, theme, Card, Button, ConfigProvider } from 'antd';
import './App.css'
// Import your components for different pages
import Home from './Pages/Home';
import Report from './Pages/Report';
import Login from './Pages/Login';
import Checklist from './Pages/Checklist';
import ChangePassword from './Pages/ChangePassword';
import AuthService from "./Services/auth";
import Siderbar from './components/Menubar';


const App = () => {

  const storedTheme = localStorage.getItem('app_theme');
  const [appTheme, setAppTheme] = useState(storedTheme || 'light');

  const isLoggedIn = AuthService.isLoggedIn();
  console.log(isLoggedIn)
  const { Header, Content, Sider, Footer } = Layout;
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items1 = [
    {
      key: 'name', label: <span style={{ 'fontSize': '16px' }}><SiJirasoftware /> <strong>ND DARBAR</strong></span>
    }
  ]
  const handleClick = () => {
    // setIsDarkMode((previousValue) => !previousValue);
    setAppTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  // Set the theme to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('app_theme', appTheme);
    if (appTheme === 'dark') {
      setIsDarkMode(true)
    } else {
      setIsDarkMode(false)
    }
  }, [appTheme]);

  return (
    <>
      <Helmet>
        <title>ND Darbar | Stock Balance</title>
      </Helmet>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        <Layout style={{
          minHeight: '100vh',
        }}>
          {isLoggedIn ?
            <Header
              style={{
                display: 'flex',
                padding: 0,
                zIndex: 11,
                // background: colorBgContainer,
                // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 2px',
              }}
              className={isDarkMode ? 'dark-theme-sider' : 'light-theme-sider'}
            >
              <Menu style={{ marginLeft: '1em' }} theme="light" mode="horizontal" defaultSelectedKeys={['2']} items={items1} />
            </Header> : null}
          <Layout>
            <Router>
              {isLoggedIn ?
                <Sider
                  breakpoint="lg"
                  collapsedWidth="0"
                  onBreakpoint={(broken) => {
                    console.log(broken);
                  }}
                  onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                  }}
                  style={{
                    // backgroundColor: '#fff',
                  }}
                  className={isDarkMode ? 'dark-theme-sider' : 'light-theme-sider'}
                >
                  <div className="demo-logo-vertical" />
                  <Siderbar />
                </Sider> : null}
              <Content
                style={{
                  margin: '24px 16px 0',
                }}
              ><div
                style={{
                  padding: 1,
                  minHeight: 360,
                  // background: colorBgContainer,
                }}
              >
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/home" exact component={Home} />
                    <Route path="/report" exact component={Report} />
                    <Route path="/checklist" component={Checklist} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/" render={() => (isLoggedIn ? <Redirect to="/home" /> : <Redirect to="/login" />)} />
                  </Switch>

                </div>
              </Content>

            </Router >
          </Layout>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            MindPerk IT Solutions LLP ©2023 Created for ND Darbar, Bijapur | <a onClick={handleClick}>  Change Theme to {isDarkMode ? "Light" : "Dark"} </a>
          </Footer>
        </Layout >
      </ConfigProvider>
    </>
  );
};

export default App;

