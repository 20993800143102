import React, { useState } from 'react';
import { Form, Input, Button, message, Divider, Row, Col } from 'antd';
import AuthService from "../../../Services/auth";
import BoxService from "../../../Services/box";

const StockForm = ({ initialValues, onSubmit }) => {

    const [loading, setLoading] = useState(false);
    const defaultValues = {
        box_id: 0,
        type: '',
        before_t: 0,
        t_weight: 0,
        after_t: 0,
        before_t_pcs: 0,
        t_pcs: 0,
        after_t_pcs: 0,
        reference: ''
    }
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(initialValues || defaultValues);

    const formItemLayout = {
        // labelCol: {
        //     span: 8,
        // },
        // wrapperCol: {
        //     span: 14,
        // },
        labelCol: {
            xs: { span: 8 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 14 },
            sm: { span: 14 },
            md: { span: 10 },
            lg: { span: 10 }
        },
    }

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const handleInputChange = (fieldName, value) => {
        if (value) {
            setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
        }
    };

    const calculateTransaction = (fieldName, value) => {
        if (value) {
            // cal
            const before_t = formData?.before_t;
            const t = value;
            const after = parseFloat(before_t) + parseFloat(t)
            if (after >= 0) {
                setFormData((prevData) => ({ ...prevData, after_t: after.toFixed(4), t_weight: value }));

            } else if (after < 0) {
                setFormData((prevData) => ({ ...prevData, after_t: '', t_weight: 0 }));
            }

        }
    }

    const calculateTransactionPcs = (fieldName, value) => {
        if (value) {
            // cal
            const before_t_pcs = formData?.before_t_pcs;
            const t = value;
            const after = parseInt(before_t_pcs) + parseInt(t)
            if (after >= 0) {
                setFormData((prevData) => ({ ...prevData, after_t_pcs: after, t_pcs: value }));

            } else if (after < 0) {
                setFormData((prevData) => ({ ...prevData, after_t_pcs: '', t_pcs: 0 }));
            }

        }
    }


    const onFinish = async () => {
        // Handle form submission, e.g., send data to the server
        console.log('Received values:', formData);
        if (!(parseFloat(formData.t_weight) > 0 || parseInt(formData.t_pcs) > 0)) {
            message.error('Transaction weight or pcs should be greater than 0.');
            return;
        }
        try {
            setLoading(true)
            const after_t = formData.after_t;
            let after_t_pcs = formData.after_t_pcs;
            after_t_pcs = parseInt(after_t_pcs)
            console.log(after_t_pcs)
            // const response = await axios.post(`${apiUrl}record`, formData);
            BoxService.pushTransaction(formData).then(
                (response) => {
                    if (response.data.status === 'success') {
                        form.setFieldsValue({
                            type: "",
                            reference: "",
                            before_t: after_t,
                            t_weight: 0,
                            after_t: 0,
                            before_t_pcs: after_t_pcs,
                            t_weight_pcs: 0,
                            after_t_pcs: 0,
                        });
                        setFormData((prevData) => ({ ...prevData, before_t: after_t, t_weight: '', after_t: '', before_t_pcs: after_t_pcs, t_pcs: '', after_t_pcs: '', reference: '' }));
                        console.log(formData)
                        //message.success(response.data.message)
                        onSubmit(response.data);
                        setLoading(false)
                    } else {
                        //message.error('Form submission failed')
                        onSubmit({ status: 'failed' });
                        setLoading(false)
                    }
                },
                (error) => {
                    console.log("Private page", error.response);
                    // Invalid token
                    if (error.response && error.response.status === 401 || error.response.status === 403) {
                        AuthService.logout();
                        window.location.href = '/login';
                        window.location.reload();
                    }
                }
            );

        } catch (error) {
            message.error('Form submission failed')
            console.error('Error submitting form:', error);
            onSubmit({ status: 'failed' });
        }
    };


    return (
        <Form
            form={form}
            name="box-form"
            onFinish={onFinish}
            {...formItemLayout}
            initialValues={initialValues}
        >
            <Input
                type='hidden'
                name='box_id'
                value={formData.box_id}
            />

            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Box No."
                        name="number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Box Number'
                            },
                            { type: 'text' },
                        ]}
                    >
                        <Input
                            readOnly
                            name='number'
                            placeholder="Box number"
                            value={formData.number}

                        />

                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Box Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Box Name'
                            },
                            { type: 'text' },
                        ]}
                    >
                        <Input
                            readOnly
                            name='name'
                            placeholder="Box name"
                            value={formData.name}
                        />
                    </Form.Item>
                </Col>
            </Row>


            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Wt. Before T"
                        name="before_t"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value'
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            name='before_t'
                            value={formData.before_t}
                            onChange={(e) => handleInputChange('before_t', e?.target?.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="T Weight"
                        name="t_weight"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Transaction Weight'
                            },
                            { type: 'decimal' },
                        ]}
                    >
                        <Input
                            name='t_weight'
                            placeholder="Transaction Weight"
                            value={formData.t_weight}
                            onChange={(e) => calculateTransaction('t_weight', e?.target?.value)}
                            type='number'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Wt. After T "
                        name="after_t"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value',
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            value={formData.after_t}
                            onChange={(e) => handleInputChange('after_t', e?.target?.value)}
                        />
                        <span style={{ display: 'none' }}>{formData.after_t}</span>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        label="Pcs Before T"
                        name="before_t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value'
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            name='before_t_pcs'
                            value={formData.before_t_pcs}
                            onChange={(e) => handleInputChange('before_t_pcs', e?.target?.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="T Pcs"
                        name="t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Transaction Pcs'
                            },
                            { type: 'decimal' },
                        ]}
                    >

                        <Input
                            name='t_pcs'
                            placeholder="Transaction Pcs"
                            value={formData.t_pcs}
                            onChange={(e) => calculateTransactionPcs('t_pcs', e?.target?.value)}
                            type='number'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Pcs After T "
                        name="after_t_pcs"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter value',
                            },
                        ]}
                    >
                        <Input
                            readOnly
                            value={formData.after_t_pcs}
                            onChange={(e) => handleInputChange('after_t_pcs', e?.target?.value)}
                        />
                        <span style={{ display: 'none' }}>{formData.after_t_pcs}</span>
                    </Form.Item>
                </Col>
            </Row>


            <Form.Item
                label="Reference"
                name="reference"
                rules={[
                    {
                        required: true,
                        message: 'Please enter reference for transaction.'
                    },
                    { type: 'text' },
                ]}
            >
                <Input
                    name='reference'
                    placeholder="Reference for transaction"
                    value={formData.reference}
                    onChange={(e) => handleInputChange('reference', e?.target?.value)}
                />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default StockForm;
