import React, { useEffect, useState, useRef, useContext } from 'react';
import { Table, Divider, Button, Drawer, Space, Tabs, Input, message, Row, Col, Modal, Card, Form, InputNumber } from 'antd';
import { SearchOutlined, EditTwoTone, PlusCircleTwoTone, CarryOutTwoTone, ProfileTwoTone, ContainerTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import AuthService from "../Services/auth";
import BoxService from "../Services/box";
import Box from '../components/Tabs/Box';
import Sale from '../components/Tabs/Sale';
import Stock from '../components/Tabs/Stock'
import Report from '../components/Tabs/Reports'


const { Search } = Input;
const App = () => {
  const history = useHistory();
  const apiUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_URL_LOCAL
      : process.env.REACT_APP_API_URL_PROD;

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [current, setCurrent] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        tableData()
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const initialBoxValues = {
    category: 0,
    id: 0,
    name: '',
    number: '',
    alert: 0,
    weight: 0,
  };

  const columns = [
    {
      title: 'Box Number',
      dataIndex: 'number',
      width: '20%',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="dashed"
            danger={(parseFloat(record?.weight) < parseFloat(record?.alert)) ? true : false}
            onClick={() => openDrawer(record)}
          >
            {record.number}
          </Button>
        </Space>
      ),
    },
    {
      title: 'Item Name',
      className: 'name',
      dataIndex: 'name',
      width: '30%',
      // align: 'right',
    },
    {
      title: 'Alert Qty',
      dataIndex: 'alert',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
    },
    {
      title: 'Sale Qty',
      dataIndex: 'sale',
      editable: true,
      width: '30%',
    },
  ];

  const onSearch = (value, _e, info) => {
    filter(value)
  };

  const onAlertFilter = (value, _e, info) => {
    filterAlert(value)
  };

  const filter = (keyword) => {
    const fetchData = async () => {
      try {
        //const response = await axios.get(`${apiUrl}boxes?keyword=${keyword}`);
        //setBoxes(response.data);
        setLoading(true)
        BoxService.searchBoxes(`keyword=${keyword}`).then(
          (response) => {
            setBoxes(response.data);
            setLoading(false)
          },
          (error) => {
            console.log("Private page", error.response);
            // Invalid token
            if (error.response && error.response.status === 401 || error.response.status === 403) {
              AuthService.logout();
              window.location.href = '/login';
              // window.location.reload();
            }
          }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }

  const filterAlert = () => {
    const fetchData = async () => {
      try {
        //const response = await axios.get(`${apiUrl}boxes?alert=1`);
        //setBoxes(response.data);
        setLoading(true)
        BoxService.searchBoxes(`alert=1`).then(
          (response) => {
            setBoxes(response.data);
            setLoading(false)
          },
          (error) => {
            console.log("Private page", error.response);
            // Invalid token
            if (error.response && error.response.status === 401 || error.response.status === 403) {
              AuthService.logout();
              window.location.href = '/login';
              // window.location.reload();
            }
          }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }

  const tableData = () => {
    BoxService.getAllBoxes().then(
      (response) => {
        setBoxes(response.data);
        setLoading(false)
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
          window.location.href = '/login';
          // window.location.reload();
        }
      }
    );
  }

  const openDrawer = (d) => {
    setCurrent(d)
    setSize('large');
    setOpen(true);
  }

  const onClose = () => {
    setCurrent({})
    setOpen(false);
  };

  const ComponentEdit = () => {
    const currentBoxValues = {
      category: 0,
      id: current?.id,
      name: current?.name,
      number: current?.number,
      alert: parseFloat(current?.alert),
      weight: current?.weight,
    };
    return (<>
      <div style={{ marginTop: "20px" }}>
        <Box initialValues={currentBoxValues} onSubmit={handleFormSubmit} />
      </div>
    </>)
  }

  const ComponentSale = () => {
    const before_t = parseFloat(current?.weight);
    const before_t_pcs = parseInt(current?.pcs);
    const currentBoxValues = {
      box_id: current?.id,
      type: '',
      reference: '',
      name: current?.name,
      number: current?.number,
      before_t: before_t.toFixed(4),
      t_weight: 0,
      after_t: before_t.toFixed(4),
      before_t_pcs: before_t_pcs,
      t_pcs: 0,
      after_t_pcs: before_t_pcs
    };
    return (<>
      <div style={{ marginBottom: "10px" }}>
        <Sale initialValues={currentBoxValues} onSubmit={handleFormSubmit} />
      </div>
    </>)
  }

  const ComponentStock = () => {
    const before_t = parseFloat(current?.weight);
    const before_t_pcs = parseInt(current?.pcs);
    const currentBoxValues = {
      box_id: current?.id,
      type: 'ADDSTOCK',
      reference: '',
      name: current?.name,
      number: current?.number,
      before_t: before_t.toFixed(4),
      t_weight: 0,
      after_t: before_t.toFixed(4),
      before_t_pcs: before_t_pcs,
      t_pcs: 0,
      after_t_pcs: before_t_pcs
    };
    return (<>
      <div style={{ marginBottom: "10px" }}>
        <Stock initialValues={currentBoxValues} onSubmit={handleFormSubmit} />
      </div>
    </>)
  }

  const ComponentReport = () => {
    return (<>
      <div style={{ marginBottom: "10px" }}>
        <Report boxId={current.id} />
      </div>
    </>)
  }

  const items = [
    {
      key: '1',
      label: <span> <EditTwoTone /> Edit Box </span>,
      children: <ComponentEdit />,
    },
    {
      key: '2',
      label: <span> <CarryOutTwoTone /> Sale </span>,
      children: <ComponentSale />,
    },
    {
      key: '3',
      label: <span> <PlusCircleTwoTone /> Add Stock </span>,
      children: <ComponentStock />,
    },
    {
      key: '4',
      label: <span> <ProfileTwoTone /> Report </span>,
      children: <ComponentReport />,
    },
  ];

  const handleFormSubmit = (response) => {
    console.log(response)
    if (response.status === 'success') {
      setModalOpen(false)
      tableData();
      if (current?.id) {
        setCurrent(response.record)
      }
      message.success(response.message);
    } else {
      message.error('Error submission failed!');
    }
  };


  const EditableContext = React.createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        console.log(values)
        if (values.id) {
          message.error('Invalid transaction');
          setLoading(false)
          return
        }
        if (isNaN(values.sale) || parseFloat(values.sale) === 0) {
          message.error('Invalid transaction');
          setLoading(false)
          return
        }
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
        // console.log('Saved:', record);
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Row>
              <Col span={16}>
                <InputNumber ref={inputRef} style={{ width: '100%' }}
                  //inputMode="numeric" // Set inputMode to "numeric" for numeric keyboard on mobile
                  type='number'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/(,*)/g, '')}
                  step={0.1} // Set the step to allow decimal places
                // onPressEnter={save} onBlur={save} 
                /></Col>
              <Col span={8}>
                <Button type="primary" htmlType="submit" onClick={save}>Save</Button></Col>
            </Row>

          </Form.Item>
        </>


      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const mcolumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row) => {
    // console.log(row)
    // const newData = [...boxes];
    // const index = newData.findIndex((item) => row.id === item.id);
    // const item = newData[index];
    // newData.splice(index, 1, {
    //   ...item,
    //   ...row,
    // });
    // setBoxes(newData);
    setLoading(true)
    console.log('Received values:', row);

    const after_t = parseFloat(row.weight) - parseFloat(row.sale)
    if (after_t < 0 || after_t === null) {
      message.error('Sale qty is more than present weight.');
      setLoading(false)
      return
    }
    const value = {
      type: "SALE",
      reference: "sale",
      before_t: row.weight,
      t_weight: row.sale,
      after_t: after_t,
      before_t_pcs: row.pcs,
      t_pcs: 0,
      after_t_pcs: row.pcs,
      box_id: row.id,
      number: row.number
    }
    console.log('Post values:', value);

    try {
      //const response = await axios.post(`${apiUrl}boxes`, formData);
      BoxService.pushTransaction(value).then(
        (response) => {
          // setBoxes(response.data);
          message.success('Record added successfully.');
          tableData();
          setLoading(false)
        },
        (error) => {
          console.log("Private page", error.response);
          // Invalid token
          if (error.response && error.response.status === 401 || error.response.status === 403) {
            AuthService.logout();
            window.location.href = '/login';
            window.location.reload();
          }
        }
      );

    } catch (error) {
      console.error('Error submitting form:', error);
      // onSubmit({ status: 'failed' });
      setLoading(false)
    }
  }
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title={<Divider orientation="left">Boxes</Divider>}
            extra={<Button type="primary" onClick={() => setModalOpen(true)}>
              Add New Box
            </Button>} >

            <Row>
              <Col xs={24} sm={24} md={16} lg={16} className='mt-1'>
                <Search
                  placeholder="Search by Box Number like S-box 1"
                  allowClear
                  enterButton="Search"
                  //size="large"
                  onSearch={filter}
                />
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className='mt-1'>
                <Button onClick={onAlertFilter}>Show Red Box</Button>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'right' }} className='mt-1'>
                <Button onClick={tableData} placement={'right'}>Clear All</Button>
              </Col>
            </Row>
            <Divider orientation="left"></Divider>
            <Table
              loading={loading}
              components={components}
              size='small'
              rowKey="number"
              columns={mcolumns}
              dataSource={boxes}
              bordered
              pagination={false}
            //  title={() =>''}
            //footer={() => 'Footer'}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Adding new box details"
        centered
        open={modalOpen}
        onOk={false}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div style={{ marginTop: "20px" }}>
          <Box initialValues={initialBoxValues} onSubmit={handleFormSubmit} />
        </div>
      </Modal>
      <Drawer
        title={`${current.number}`}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onClose}>
              OK
            </Button>
          </Space>
        }

      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Tabs defaultActiveKey="2" items={items} centered />
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default App;